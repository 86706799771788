import React, { useState } from "react";
import NukaCarousel from "nuka-carousel";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import LazyImage from "./LazyImage";
import { OptimizedImage } from "@src/@types/image";

interface Props {
  images: Array<{
    src: string | OptimizedImage;
    alt: string;
  }>;
  height?: number;
}

export default function Carousel({ images, height }: Props) {
  const [slideIndex, setSlideIndex] = useState(0);
  const items = images.map((img, i) => {
    if (i <= slideIndex + 2) {
      return (
        <LazyImage
          key={typeof img.src === "string" ? img.src : img.src.src}
          img={typeof img.src === "string" ? img.src : img.src.src}
          alt={img.alt}
          style={{
            height: height || 250,
            objectFit: "cover",
            objectPosition: "center",
          }}
        />
      );
    } else {
      return <></>;
    }
  });
  return (
    <NukaCarousel
      heightMode="first"
      defaultControlsConfig={{
        pagingDotsStyle: {
          padding: 8,
          fill: "white",
        },
      }}
      afterSlide={setSlideIndex}
      renderCenterLeftControls={({ previousSlide }) => (
        <ChevronLeftIcon
          boxShadow="rgba(0, 0, 0, 0.1) 0px 0px 12px, rgba(0, 0, 0, 0.04) 0px 6px 6px"
          fontSize="5xl"
          color="white"
          onClick={previousSlide}
        />
      )}
      renderCenterRightControls={({ nextSlide }) => (
        <ChevronRightIcon
          boxShadow="rgba(0, 0, 0, 0.1) 0px 0px 12px, rgba(0, 0, 0, 0.04) 0px 6px 6px"
          fontSize="5xl"
          color="white"
          onClick={nextSlide}
        />
      )}
      style={{ height: height || 250 }}
    >
      {items}
    </NukaCarousel>
  );
}
