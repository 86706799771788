import React, { useEffect, useState } from "react";
import { getInstagramPost, Post } from "@src/api/instagram";
import LazyImage from "./LazyImage";
import Carousel from "./Carousel";
import InstaUsernameCaption from "@src/components/InstaUsernameCaption";

interface Props {
  postId: string;
  include?: number[];
  exclude?: number[];
  disableCarousel?: boolean; // if true, only first image will be shown
}

export default function InstaImage({
  postId,
  disableCarousel,
  include,
  exclude,
}: Props) {
  const [post, setPost] = useState<Post | undefined>();
  useEffect(() => {
    getInstagramPost(postId).then((data) => {
      setPost(data);
    });
  }, []);
  if (!post) {
    return <></>;
  }
  const elements = [];
  let imgUrls = post.imgUrls;
  if (include) {
    imgUrls = imgUrls.filter((_, i) => include.includes(i));
  }
  if (exclude) {
    imgUrls = imgUrls.filter((_, i) => !exclude.includes(i));
  }
  if (imgUrls.length > 1 && !disableCarousel) {
    elements.push(
      <Carousel
        key="carousel"
        images={imgUrls.map((url) => ({ src: url, alt: "" }))}
      />
    );
  } else {
    elements.push(
      <LazyImage key="image" img={imgUrls[0] || post.displayImgUrl} alt="" />
    );
  }
  elements.push(<InstaUsernameCaption username={post.username} />);
  return elements;
}
