import { OptimizedImage } from "@src/@types/image";
import { mediaAuthorsMurakamiMasanoriImage } from "./images";

export interface Author {
  name: string;
  img: OptimizedImage;
  profile: string;
}

export const murakamiMasanori: Author = {
  name: "ターキー",
  img: mediaAuthorsMurakamiMasanoriImage,
  profile:
    "ワインとイタリアンが大好きな関西弁を話す26歳。レツミルから行列のできる人気店の情報などを伝えていきます。",
};
