import React from "react";
import Text from "@src/components/Text";
import { FaInstagram } from "react-icons/fa";

interface Props {
  username: string;
}

export default function InstaUsernameCaption({ username }: Props) {
  return (
    <Text
      key="user"
      as="cite"
      display="flex"
      flexDirection="row"
      alignItems="center"
      py={2}
      fontSize="sm"
    >
      <FaInstagram size={18} color="#4A5568" />
      <Text ml={1} color="gray.600">
        {username}
      </Text>
    </Text>
  );
}
