export interface Post {
  displayImgUrl: string;
  imgUrls: string[];
  caption: string;
  username: string;
}

export const getInstagramPostsByHashtag = async (hashtag: string) => {
  const errorHandling = (e) => {
    throw Error(`failed to fetch instagram posts by tag(${hashtag}): ${e}`);
  };
  const res = await fetch(
    `https://www.instagram.com/explore/tags/${hashtag}/?__a=1`
  );
  const data = await res.json().catch(errorHandling);
  const posts = data.graphql.hashtag.edge_hashtag_to_media.edges
    .filter(({ node }) => node.accessibility_caption)
    .map(({ node }: any) => ({
      username: node.accessibility_caption.split(" ")[2],
      displayImgUrl: node.display_url,
      imgUrls: node.thumbnail_resources.map((thumbnail) => thumbnail.src),
      caption: node.edge_media_to_caption.edges[0].node.text,
    }));
  return posts;
};

export const getInstagramPost = async (postId: string) => {
  const errorHandling = (e) => {
    throw Error(`failed to fetch instagram post(${postId}): ${e}`);
  };
  const res = await fetch(
    `https://www.instagram.com/explore/p/${postId}/?__a=1`
  ).catch(errorHandling);
  const data = await res.json().catch(errorHandling);
  const media = data.graphql.shortcode_media;
  let imgUrls = [];
  if (media.edge_sidecar_to_children) {
    imgUrls = media.edge_sidecar_to_children.edges.map(
      (edge) => edge.node.display_url
    );
  }
  return {
    displayImgUrl: media.display_url,
    imgUrls,
    caption: media.edge_media_to_caption.edges[0].node.text,
    username: media.owner.username,
  };
};
